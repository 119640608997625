<template>
    <div class="expand">
        <img src='@/assets/images/expand/top.png' class='bg'/>
        <img src='@/assets/images/expand/logo1.png' class='logo'/>
        <div class='test'>
            <div>跨境直播带货联盟</div>
            <div class='tips'>fastest-growing Ad & Marketing agencies</div>
        </div>
        <div class='shadow'></div>
    </div>
    <div class='content'>
        <img src='@/assets/images/expand/t1.png' class='t1'/>
        <img src='@/assets/images/expand/t2.png' class='t2'/>
        <img src='@/assets/images/expand/t3.png' class='t3'/>
        <img src='@/assets/images/expand/t4.png' class='t4'/>
        <img src='@/assets/images/expand/main_title.png' class='title_img'/>
        <div class='about'>ABOUT US</div>
        <div class='about1'>关于我们</div>
        <div class='dero'>Wahool成立于2014年，分别在北京和纽约两地设有办公地，是国内发展最快的跨境服务商之一。Wahool专注于直播和内容视频营销，旗下直签海量优质美国本土达人，已为超过200+品牌提供营销服务！在拥有丰富的品牌产品营销经验同时，还能够通过视频营销和达人推广更好的帮助中国卖家打造更优质的跨境品牌。</div>
        <div class='merchant'>
           <img src='@/assets/images/expand/c1.png'/>
           <img src='@/assets/images/expand/c2.png'/>
           <img src='@/assets/images/expand/c3.png'/>
           <img src='@/assets/images/expand/c4.png'/>
           <img src='@/assets/images/expand/c5.png'/>
           <img src='@/assets/images/expand/c6.png'/>
           <img src='@/assets/images/expand/c7.png'/>
           <img src='@/assets/images/expand/c8.png'/>
           <img src='@/assets/images/expand/c9.png'/>
           <img src='@/assets/images/expand/c10.png'/>
           <img src='@/assets/images/expand/c11.png'/>
           <img src='@/assets/images/expand/c12.png'/>
        </div>
    </div>
    <div class='bottom'>
        <img src='@/assets/images/expand/code.png' class='code'/>
        <img src='@/assets/images/expand/logo.png' class='logo'/>
    </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style lang="less" scoped>
    .shadow{
        position: absolute;
        width: 100%;
        height: 80%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        bottom:0;
        left: 0;
        z-index: 2;
    }
    .expand{
        width: 100%;
        min-width: 700px;
        position: relative;
        .bg{
            width: 100%;
        }
        .logo{
            width: 35%;
            position: absolute;
            left:50%;
            margin-left:-17.5%;
            z-index: 3;
            top: 30%;
        }
        .test{
            text-align: center;
            font-size: 30px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            position: absolute;
            width: 100%;
            left:0;
            top:47%;
            z-index: 3;
            .tips{
                font-size: 21px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    .content{
        padding-top:93px;
        padding-bottom: 150px;
        background: #171719;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        min-width: 700px;
        .t1{
            position: absolute;
            width: 10vw;
            z-index: 3;
            top:3.8vw;
            right: 13vw;
        }
        .t2{
            position: absolute;
            width: 16.17vw;
            z-index: 3;
            top:8vw;
            right: 0px;
        }
        .t3{
            position: absolute;
            width: 21.41vw;
            z-index: 3;
            top:458px;
            left: 0px;
        }
        .t4{
            position: absolute;
            width: 6.33vw;
            z-index: 3;
            top:842px;
            left: 0px;
        }
        .title_img{
            width:449px;
        }
        .about{
            font-size: 31px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #FFFFFF;
            margin-top:104px;
        }
        .about1{
            font-size: 25px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #FFFFFF;
        }
        .dero{
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #FFFFFF;
            margin-top:38px;
            width: 670px;
        }
        .merchant{
            width: 670px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top:140px;
            img{
                width: 30%;
                margin-bottom: 29px;
            }
        }
    }
    .bottom{
        padding-top:44px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 700px;
        .code{
            width: 179px;
        }
        .logo{
            width: 119px;
            margin-top:19px;
        }
    }
</style>